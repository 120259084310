import Button from '@components/Button/Button';
import FormInput from '@components/Form/Input/FormInput';
import { Body1, Heading4 } from '@components/Texts/Texts';
import tw, { styled } from 'twin.macro';

export const Container = styled.div`
  ${tw`flex flex-col`}
`;

export const Heading = styled(Heading4)`
  ${tw`sm:text-center`}
  ${tw`md:text-center`}
`;

export const WhiteText = styled.span`
  ${tw`text-light-high`}
`;

export const YellowText = styled.span`
  ${tw`text-brand-primary`}
`;

export const Description = styled(Body1)`
  ${tw`sm:text-center`}
  ${tw`md:text-center`}
  ${tw`mt-1 mb-3 font-semibold text-light-low`};
`;

export const FormWrapper = styled.form``;

export const FormRow = styled.div`
  ${tw`flex flex-row`};
`;

export const StyledInput = styled(FormInput)`
  ${tw`sm:h-6`}
`;

export const StyledButton = styled(Button)`
  ${tw`ms-2 uppercase w-auto`};
  ${tw`sm:h-5 sm:px-3 sm:py-0 sm:my-0.5`}
`;

export const TermsAndPrivacyContainer = styled.div`
  ${tw`sm:text-center`}
  ${tw`md:text-center`}
  ${tw`mt-2`};
`;
