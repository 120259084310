export const I18N_HOME_CONTEXT = 'home';
export const EMAIL_OPT_IN_HEADER = 'email-opt-in-header';
export const EMAIL_OPT_IN_DESCRIPTION = 'email-opt-in-description';
export const EMAIL_OPT_IN_PLACEHOLDER = 'email-opt-in-placeholder';
export const EMAIL_OPT_IN_CTA = 'email-opt-in-cta';
export const EMAIL_OPT_IN_TERMS_PREFIX = 'email-opt-in-terms-prefix';
export const EMAIL_OPT_IN_TERMS_LINK = 'email-opt-in-terms-link';
export const EMAIL_OPT_IN_PRIVACY_PREFIX = 'email-opt-in-privacy-prefix';
export const EMAIL_OPT_IN_PRIVACY_LINK = 'email-opt-in-privacy-link';
export const EMAIL_OPT_IN_ALREADY_EXIST = 'email-opt-in-already-exist';
export const EMAIL_OPT_IN_INVALID = 'email-opt-in-invalid';
