import Joi from 'joi';

export type EmailOptInFormProps = {
  openPopup: () => void;
};

export interface EmailOptInData {
  email: string;
}

export const EmailOptInInputs = Joi.object<EmailOptInData>({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});
