import { SeoLink } from '@common/SeoLink';
import { Body1, Body2 } from '@components/Texts/Texts';
import { Container, Underline } from './TextWithLink.styles';
import { TextWithLinkProps } from './TextWithLink.types';

const TextWithLink = ({ type, prefix, link, suffix, onClick, href }: TextWithLinkProps): JSX.Element => {
  const baseStructure = (
    <>
      {prefix}
      {href && <SeoLink href={href} />}
      <Underline onClick={onClick}>{link}</Underline>
      {suffix}
    </>
  );

  return <Container>{type == 'small' ? <Body2>{baseStructure}</Body2> : <Body1>{baseStructure}</Body1>}</Container>;
};

export default TextWithLink;
