import * as MixpanelHomePageActions from '@analytics/mixpanel/actions/HomePage';
import * as HomePageAPI from '@api/HomePage';
import FormElement from '@components/Form/Element/FormElement';
import { useYelaForm } from '@components/Form/Form';
import TextWithLink from '@components/TextWithLink/TextWithLink';
import Routes from '@constants/routes';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  EMAIL_OPT_IN_ALREADY_EXIST,
  EMAIL_OPT_IN_CTA,
  EMAIL_OPT_IN_DESCRIPTION,
  EMAIL_OPT_IN_HEADER,
  EMAIL_OPT_IN_INVALID,
  EMAIL_OPT_IN_PLACEHOLDER,
  EMAIL_OPT_IN_PRIVACY_LINK,
  EMAIL_OPT_IN_PRIVACY_PREFIX,
  EMAIL_OPT_IN_TERMS_LINK,
  EMAIL_OPT_IN_TERMS_PREFIX,
  I18N_HOME_CONTEXT,
} from './EmailOptInForm.constants';
import {
  Container,
  Description,
  FormRow,
  FormWrapper,
  Heading,
  StyledButton,
  StyledInput,
  TermsAndPrivacyContainer,
  WhiteText,
  YellowText,
} from './EmailOptInForm.styles';
import { EmailOptInData, EmailOptInFormProps, EmailOptInInputs } from './EmailOptInForm.types';

const EmailOptInForm = ({ openPopup }: EmailOptInFormProps): JSX.Element => {
  const { t } = useTranslation(I18N_HOME_CONTEXT);
  const router = useRouter();
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string | undefined>();
  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useYelaForm<EmailOptInData>(EmailOptInInputs);

  useEffect(() => {
    reset();
    setStatusMessage(undefined);
  }, [router]);

  const processJoin: SubmitHandler<EmailOptInData> = async data => {
    if (buttonIsLoading) return;

    await trigger();
    if (errors === undefined || errors == {}) return;

    setButtonIsLoading(true);
    const responseData = await HomePageAPI.EmailOptIn({ email: data.email });
    if (responseData.success) {
      MixpanelHomePageActions.onJoinMailingListSuccess({ router });
      setButtonIsLoading(false);
      openPopup();
      reset();
    } else {
      MixpanelHomePageActions.onJoinMailingListFailed({ router, failureInfo: { type: 'Server Error', errorCode: responseData.code } });
      if (responseData.code == 'already-opt-in') setStatusMessage(t(EMAIL_OPT_IN_ALREADY_EXIST));
      setButtonIsLoading(false);
    }
  };

  const onSubmitClick = () => {
    MixpanelHomePageActions.onJoinMailingListButtonClicked({ router });
    if (errors.email) {
      MixpanelHomePageActions.onJoinMailingListFailed({ router, failureInfo: { type: 'Invalid Field' } });
      setStatusMessage(t(EMAIL_OPT_IN_INVALID));
    } else {
      setStatusMessage(undefined);
    }
  };

  return (
    <Container>
      <Heading>
        <WhiteText>
          <Trans i18nKey={`${I18N_HOME_CONTEXT}:${EMAIL_OPT_IN_HEADER}`} components={[<YellowText key='0' />]} />
        </WhiteText>
      </Heading>
      <Description>{t(EMAIL_OPT_IN_DESCRIPTION)}</Description>
      <FormWrapper onSubmit={handleSubmit(processJoin)} noValidate={true}>
        <FormRow>
          <FormElement hasError={!!statusMessage} labelText={''} errorText={statusMessage || '-'}>
            <StyledInput
              id='email-opt-in-input'
              hasError={!!statusMessage}
              name='email'
              type='email'
              register={register}
              placeholder={t(EMAIL_OPT_IN_PLACEHOLDER)}
            />
          </FormElement>
          <StyledButton type='submit' text={t(EMAIL_OPT_IN_CTA)} loadingState={buttonIsLoading} onClick={onSubmitClick} />
        </FormRow>
      </FormWrapper>
      <TermsAndPrivacyContainer>
        <TextWithLink
          type='small'
          prefix={t(EMAIL_OPT_IN_TERMS_PREFIX)}
          link={t(EMAIL_OPT_IN_TERMS_LINK)}
          suffix={''}
          onClick={() => {
            router.push(Routes.TERMS_AND_CONDITIONS);
          }}
          href={Routes.TERMS_AND_CONDITIONS}
        />
        <TextWithLink
          type='small'
          prefix={t(EMAIL_OPT_IN_PRIVACY_PREFIX)}
          link={t(EMAIL_OPT_IN_PRIVACY_LINK)}
          suffix={''}
          onClick={() => {
            router.push(Routes.PRIVACY_POLICY);
          }}
          href={Routes.PRIVACY_POLICY}
        />
      </TermsAndPrivacyContainer>
    </Container>
  );
};

export default EmailOptInForm;
